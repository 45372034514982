import React, { useState, useEffect } from "react"
import Seo from "../../components/Core/Seo"
import Layout from "../../components/Layout/Layout"
import colors from "../../styles/colors"
import AccountNavigation from "../../components/AccountContent/AccountNavigation"
import axios from "axios"
import swell, { account } from "swell-js"
import async from "async"
import { useUserContext } from "../../context/UserContext"
import { useInsertionEffect } from "react"
import { useStaticQuery, StaticQuery, graphql } from "gatsby"

import ReferAFriend from "../../components/AccountContent/ReferAFriend"

const ReferAFriendPage = (props) => {
  const { location } = props
  const path = location.pathname

  const data = useStaticQuery(graphql`
    {
        contentfulSimplePage(slug: { eq: "refer-a-friend" }) {
          ...simplePageFragment
        }
      }
  `)
  const page = data.contentfulSimplePage

  // const {user} = useUserContext();

 
  return (
    <Layout dark bg={colors.accountGrey}>
      <Seo
        title="Containers Page"
        description="In Good Company Refer-A-Friend Page"
        fullTitle="Refer-A-Friend Page"
        path={path}
      />
      <AccountNavigation path={path}>
    
      <ReferAFriend content={page.content}/>
      
      </AccountNavigation>

   
    </Layout>
  )
}

export default ReferAFriendPage
