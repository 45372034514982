import React, { useState, useEffect, useRef } from "react"
import axios from "axios"
import { couldStartTrivia, getTypeParameterOwner } from "typescript";
import { useUserContext } from "../../context/UserContext"
import { getUser } from "../../services/user/user"
import Text from "../Blocks/Text"
import styled from "styled-components"




  
const ReferralCodeText = styled.h4`
  background-color:#319AD5; 
  color: white;
`

export default function Component ({content}) {
  
  let referralData = "";
  let referralData2 = "";
  const [referrals, setReferrals] = useState([]);
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)
  const [code, setCode] = useState("")
  const {user, setUser} = useUserContext()

  useEffect(() => {
    
   if (referrals !== null && referrals.length > 0){
     setIsLoading(false)
   }
   else if(user !== null){
     getReferrals(user.id)
     setIsLoading(true)
   } else{
      getUser()
      .then((res) => {
        setUser(res.results).then((response)=>{
          getReferrals(response.id)
        })
        setIsLoading(true)
      })
      .catch((e) => {
        console.error(e)
        setIsError(true)
      })
   }
  })
  
    const getReferrals= (accountId) =>  {
      console.log(user)
      let response
      try{
        const url = "/.netlify/functions/getReferrals"
        
        const referralBody = {
          accountId
        }
        
        response = axios.post(url, referralBody).then(result=> {  if (result.data == "no referrals found"){ setIsError(true)} else if (result.data && result.data.referral_code){ setIsError(true); setCode(result.data.referral_code)  } else{ setReferrals(result.data.referralData.results)}})
        if (response?.status === 200) {
     
        }  
      } catch (error) {
        console.error(error)
      }
      try {return response.data;}catch(e){
        return e
      }
    
  }
if (isError && code){
  return <><h3>
  Referrals
  </h3>
  <ReferralCodeText>Your Code: {code}</ReferralCodeText>
  <p>No Referrals Found. Share your referral code with a friend to earn credit.</p></>
} else if (isError){
  return <><h3>
  Referrals
  </h3>
  <p>You can refer a friend after you have placed your first order</p></>
}
if (isLoading) {
    return (
      <><h3>
        Referrals
      </h3>
      <p>Loading Referrals...</p></>
    )
  }
  
  let creditsEarned = referrals.reduce((accumulator, object)=>{
    return accumulator + object.referrer_reward_amount
  }, 0);
  
  referralData2 += "<h4 style='background-color:#319AD5; color: white'>"
  referralData2 += "Your Code: "+referrals[0].referral_code+"</h4>" 
  referralData2 +="<hr>"
  referralData2 += "<ul>"
  referralData2 += "<li>"+"Your Referrals: "+referrals.length+"</li>"
  referralData2 += "<li>"+"Total Credits Earned: "+creditsEarned+"</li>"
  referralData2 += "<li>"+"Total Credits Available: "+referrals[0].account_balance+"</li>"
  referralData2 += "</ul>"
  
  
  return (
  <>
      <h3>Refer A Friend</h3>
      <div dangerouslySetInnerHTML={{__html: referralData}}></div>
      <Text content={content}/>
      <div dangerouslySetInnerHTML={{__html: referralData2}}></div>
  </>
    );
}
